import './App.css';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from './components/views/Login/Login';
import { Projects } from './components/views/Projects';

export const server = "http://localhost:3001"

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={< Projects />} />
        <Route path="/login" element={< Login />} />
        {/*<Route path='/register' element={< AddUser />} />*/}
      </Routes>
    </div>
  );
}

export default App;