import { useEffect, useState } from "react";
import { formatTime, getUserData } from "../api/Utils";
import { server } from "../../App";
import { useNavigate } from "react-router-dom";


const NewProjectModal = ({ onClose, onSave }: any) => {
    const [newProjectName, setNewProjectName] = useState("");

    const handleSave = () => {
        onSave(newProjectName);
        setNewProjectName("");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
            <div className="bg-gray-700 p-6 shadow-lg rounded-md">
                <label className="block text-lg font-semibold mb-4 text-white">Enter project name:</label>
                <input
                    name="projectName"
                    type="text"
                    value={newProjectName}
                    onChange={(e) => setNewProjectName(e.target.value)}
                    className="w-full p-3 mb-4 border border-white bg-gray-100 rounded-md focus:outline-none focus:border-indigo-500"
                />
                <div className="flex justify-end">
                    <button onClick={onClose} className="mr-2 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none">
                        Dismiss
                    </button>
                    <button onClick={handleSave} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none">
                        Create
                    </button>
                </div>
            </div>
        </div>
    );
};

const GoalModal = ({ onClose, onSave, goal }: any) => {
    const markAsCompleted = () => {
        const sessionUserId = localStorage.getItem('user');
        fetch(`${server}/project/setgoalcompleted`, {
            method: 'POST',
            headers: {
                Authorization: sessionUserId ? sessionUserId : '',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({ id: goal.id }),
        })
            .then((response) => response.json())
            .then((data) => {

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const removeCompletedMark = () => {
        const sessionUserId = localStorage.getItem('user');
        console.log(goal.id);
        fetch(`${server}/project/removegoalcompleted`, {
            method: 'POST',
            headers: {
                Authorization: sessionUserId ? sessionUserId : '',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({ id: goal.id }),
        })
            .then((response) => response.json())
            .then((data) => {

            })
            .catch((error) => {
                console.error('Error:', error);
            });
        onClose();
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
            <div className="bg-gray-700 p-6 shadow-lg rounded-md w-96">
                <label className="block text-lg font-semibold mb-4 text-white">Edit Goal</label>

                <div className="mb-4">
                    <p className="text-gray-400">Goal set:</p>
                    <a className="text-green-500 font-bold">{formatTime(goal.created)}</a>
                </div>
                {goal.completed ? (
                    <>
                        <div className="mb-4">
                            <p className="text-gray-400">Goal completed:</p>
                            <a className="text-green-500 font-bold">{formatTime(goal.completedAt)}</a>
                        </div>
                    </>
                ) : (
                    <>
                        <button
                            onClick={markAsCompleted}
                            className="mb-4 bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                        >
                            Mark as Completed
                        </button>
                    </>
                )}

                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                    >
                        Close
                    </button>

                    <button
                        onClick={removeCompletedMark}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                    >
                        Reopen goal
                    </button>
                </div>
            </div>
        </div>

    );
};

const AddItemModal = ({ onClose, onSave, title, addItem }: any) => {
    const [itemName, setItemName] = useState("");

    const handleSave = () => {
        onSave(itemName);
        setItemName("");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-gray-800">
            <div className="bg-gray-700 p-6 shadow-lg rounded-md">
                <label className="block text-lg font-semibold mb-4 text-white">
                    {title}
                </label>
                <input
                    name="itemName"
                    type="text"
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    className="w-full p-3 mb-4 border border-white bg-gray-100 rounded-md focus:outline-none focus:border-indigo-500"
                />
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="mr-2 bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                    >
                        Dismiss
                    </button>
                    <button
                        onClick={handleSave}
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none"
                    >
                        {addItem}
                    </button>
                </div>
            </div>
        </div>
    );
};

export const Projects = () => {
    const [projects, setProjects] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const [project, setProject] = useState<any>(null);
    const [isLearningsModalOpen, setIsLearningsModalOpen] = useState(false);
    const [isGoalsModalOpen, setIsGoalsModalOpen] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [progressPercent, setProgressPercent] = useState<number>(0);
    const [isGoalModalOpen, setGoalModalOpen] = useState(false);
    const [tempId, setTempId] = useState(-1);
    const [tempData, setTempData] = useState<any>(null);

    useEffect(() => {
        const load = async () => {
            const userData = await getUserData();
            setUser(userData);

            if (!userData || userData === null) {
                navigate('/login');
                return;
            }

            loadProjects();
        };

        load();
    }, [navigate]);

    const loadProjects = async () => {
        var userIdentityToken = localStorage.getItem("user");
        const response = await fetch(server + '/project/get', {
            method: 'GET',
            headers: {
                Authorization: userIdentityToken ? userIdentityToken : '',
            },
            mode: 'cors',
        });
        const projectsData = await response.json();

        setProjects(projectsData);
        console.log(projectsData);
    }

    const handleLogout = () => {
        // Handle logout logic
    };

    const createNewProject = () => {
        setIsModalOpen(true);
    };

    const closeNewProjectModal = () => {
        setIsModalOpen(false);
    };

    const saveNewProject = (newProjectName: string) => {
        if (newProjectName.trim() !== "") {
            const sessionUserId = localStorage.getItem('user');
            fetch(`${server}/project/add`, {
                method: 'POST',
                headers: {
                    Authorization: sessionUserId ? sessionUserId : '',
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({ projectName: newProjectName }),
            })
                .then((response) => response.json())
                .then((data) => {
                    loadProjects();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            loadProjects();
        }
        closeNewProjectModal();
    };

    const loadProject = async (id: number) => {
        setLoading(true);
        var userIdentityToken = localStorage.getItem("user");
        const response = await fetch(server + '/project/get/' + id, {
            method: 'GET',
            headers: {
                Authorization: userIdentityToken ? userIdentityToken : '',
            },
            mode: 'cors',
        });
        const projectData = await response.json();
        //projectData.project = projectData.project[0];
        setProject(projectData);
        console.log(projectData);
        setLoading(false);
        setProgressPercent(project.goals.length)
    };

    const addGoal = async (goal: string) => {
        if (goal.trim() !== "") {
            const sessionUserId = localStorage.getItem('user');
            fetch(`${server}/project/addgoal`, {
                method: 'POST',
                headers: {
                    Authorization: sessionUserId ? sessionUserId : '',
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({ goal: goal, projectId: project.project.id }),
            })
                .then((response) => response.json())
                .then((data) => {
                    loadProjects();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            loadProjects();
        }
        closeGoalsModal();
    };

    const addLearning = async (learning: string) => {
        if (learning.trim() !== "") {
            console.log(learning);
            const sessionUserId = localStorage.getItem('user');
            fetch(`${server}/project/addlearning`, {
                method: 'POST',
                headers: {
                    Authorization: sessionUserId ? sessionUserId : '',
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({ learning: learning, projectId: project.project.id }),
            })
                .then((response) => response.json())
                .then((data) => {
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        closeLearningsModal();
    }
    const openLearningsModal = () => {
        setIsLearningsModalOpen(true);
    };

    const openGoalsModal = () => {
        setIsGoalsModalOpen(true);

    };

    const closeLearningsModal = () => {
        setIsLearningsModalOpen(false);
    };

    const closeGoalsModal = () => {
        setIsGoalsModalOpen(false);
    };

    const openGoalModal = (goal: any) => {
        setGoalModalOpen(true);
        setTempData(goal);
    }

    const closeGoalModal = () => {
        setGoalModalOpen(false);
    }

    const editGoal = (goal: any) => {
        closeGoalModal();
    };

    return (
        <>
            {user ? (
                <div className="flex h-screen">
                    <div className="relative flex flex-col bg-gray-950 text-gray-700 h-screen w-full max-w-[20rem] p-4 shadow-xl shadow-blue-gray-900/5">
                        <div className="mb-2 p-4 text-center">
                            <h5 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-white">Projects</h5>
                        </div>
                        <nav className="flex flex-col gap-1 min-w-[240px] p-2 font-sans text-base font-normal text-white">
                            <button
                                onClick={createNewProject}
                                role="button"
                                tabIndex={0}
                                className="bg-gray-900 flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-900 hover:bg-opacity-80 focus:bg-blue-950 focus:bg-opacity-80 active:bg-gray-900 active:bg-opacity-80  outline-none"
                            >
                                <div className="grid place-items-center mr-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white"><path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z"></path></svg>
                                </div>
                                New Project
                            </button>
                            {projects.map((project) => (
                                <button
                                    key={project.id}
                                    onClick={() => loadProject(project.id)}
                                    role="button"
                                    tabIndex={0}
                                    className="flex items-center w-full p-3 rounded-lg text-start leading-tight transition-all hover:bg-gray-900 hover:bg-opacity-80 focus:bg-blue-950 focus:bg-opacity-80 active:bg-gray-900 active:bg-opacity-80  outline-none"
                                >
                                    <div className="grid place-items-center mr-4">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            aria-hidden="true"
                                            className="h-5 w-5"
                                        >
                                            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                                        </svg>
                                    </div>
                                    {project.name}
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className="bg-gray-800 p-4 flex-grow flex flex-col items-center justify-center">
                        {project !== null && isLoading === false ? (
                            <>
                                <button onClick={openGoalsModal} className="text-white text-center text-xl mb-4">Goals</button>
                                <table className="table-auto">
                                    <tbody>
                                        {project.goals.map((goal: any, index: any) => (
                                            <tr key={index}>
                                                <td className="px-2 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
                                                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                                                    </svg>
                                                </td>
                                                <td className="text-l py-2 text-white"><button onClick={() => openGoalModal(goal)}>{goal.goal}</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <button onClick={openLearningsModal} className="text-white text-center text-xl mb-4">Learnings</button>
                                <table className="table-auto">
                                    <tbody>
                                        {project.learnings.map((learning: any, index: any) => (
                                            <tr key={index}>
                                                <td className="px-2 py-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="white">
                                                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                                                    </svg>
                                                </td>
                                                <td className="text-l py-2 text-white">{learning.learning}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                <h1 className="text-white text-center text-xl mb-4">Project State</h1>
                                <div className="w-full h-8 bg-gray-500 rounded overflow-hidden mt-4 text-center text-white">
                                    {`${progressPercent}% completed`}
                                    <div
                                        className="h-full bg-green-500 text-white text-center leading-8"
                                        style={{ width: `${progressPercent}%` }}
                                    >
                                    </div>
                                </div>
                            </>
                        ) : (
                            (!isLoading) && (
                                <h1 className="text-center text-xl text-gray-200">No Project selected</h1>
                            )
                        )}
                        {isLoading === true && (
                            <div className="flex flex-row gap-2">
                                <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                                <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.3s]"></div>
                                <div className="w-4 h-4 rounded-full bg-blue-700 animate-bounce [animation-delay:.7s]"></div>
                            </div>
                        )}
                    </div>
                    {isModalOpen && (
                        <NewProjectModal onClose={closeNewProjectModal} onSave={saveNewProject} />
                    )}
                    {isLearningsModalOpen && (
                        <AddItemModal
                            onClose={closeLearningsModal}
                            onSave={addLearning}
                            title="Add learning:"
                            addItem="Add"
                        />
                    )}
                    {isGoalsModalOpen && (
                        <AddItemModal
                            onClose={closeGoalsModal}
                            onSave={addGoal}
                            title="Add goal:"
                            addItem="Add"
                        />
                    )}
                    {isGoalModalOpen && (
                        <GoalModal
                            onClose={closeGoalModal}
                            onSave={editGoal}
                            goal={tempData}
                        />
                    )}
                </div>
            ) : (
                <>
                    <div className="flex h-screen bg-white dark:bg-gray-900 items-center justify-center text-4xl text-black dark:text-white">
                        Trying to Load this Website...
                    </div>
                </>
            )}
        </>
    );
};
